'use strict';

(function ($, NETWORLD) {
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';

	// ＜ルート設定対応＞要素が空だったら削除
	var removeElmIfEmpty = function removeElmIfEmpty() {
		$('.js-checkEmptyElm').each(function (idx, val) {
			if (!$(val).text().length) {
				$(val).remove();
			}
		});
	};
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	var removeBlockIfListEmpty = function removeBlockIfListEmpty() {
		if ($('.js-checkEmptyList').length) {
			$('.js-checkEmptyList').each(function (idx, val) {
				if (!$(val).find('li').length) {
					$(val).parents('.js-checkEmptyList-wrap').remove();
				}
			});
		}
	};
	var slider = function slider() {
		if ($('.mod-slider01').length) {
			$('.mod-slider01').slick({
				infinite: true,
				speed: 1000,
				autoplay: true,
				autoplaySpeed: 4000,
				slidesToShow: 1,
				centerMode: true,
				centerPadding: 'calc((100% - 536px) / 2)',
				arrows: true,
				prevArrow: '<button class="slick-arrow slick-prev opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_left2" /></svg></button>',
				nextArrow: '<button class="slick-arrow slick-next opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_right2" /></svg></button>',
				responsive: [{
					breakpoint: 769,
					settings: {
						centerPadding: '5%'
					}
				}]
			});
		}
		if ($('.mod-slider02').length) {
			$('.mod-slider02').slick({
				infinite: true,
				speed: 1000,
				autoplay: true,
				autoplaySpeed: 4000,
				slidesToShow: 1,
				arrows: true,
				prevArrow: '<button class="slick-arrow slick-prev opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_left2" /></svg></button>',
				nextArrow: '<button class="slick-arrow slick-next opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_right2" /></svg></button>'
			});
		}
		if ($('.mod-slider03').length) {
			$('.mod-slider03').slick({
				infinite: true,
				speed: 1000,
				autoplay: true,
				autoplaySpeed: 4000,
				slidesToShow: 1,
				arrows: true,
				dots: true,
				prevArrow: '<button class="slick-arrow slick-prev opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_left2" /></svg></button>',
				nextArrow: '<button class="slick-arrow slick-next opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_right2" /></svg></button>'
			});
		}
		if ($('.js-solution-slider').length) {
			(function () {
				var getJSONmaker = function getJSONmaker(_id) {
					return $.getJSON('/ajax/solution/pickup/maker/' + _id + '.json');
				};
				var getJSONkeyword = function getJSONkeyword(_id) {
					return $.getJSON('/ajax/solution/pickup/keyword/' + _id + '.json');
				};

				var _loop = function _loop(i) {
					getJSONmaker($('.js-solution-slider .mod-slider02_style-item').eq(i).find('.rt_cf_s_pickup_code').text()).then(function (data) {
						// console.log(data.rt_bn_maker_join,'getJSONmaker');
						var markerList = '';
						for (var l = 0; l < data.rt_bn_maker_join.length; l++) {
							markerList += '<li><span class="mod-label-keyword mod-label-keyword02 opacity">' + data.rt_bn_maker_join[l].cf_m_name + '</span></li>';
						}
						$('.js-solution-slider .mod-slider02_style-item').eq(i).find('.js-solution-slider-maker').append(markerList);
						getJSONkeyword($('.js-solution-slider .mod-slider02_style-item').eq(i).find('.rt_cf_s_pickup_code').text()).then(function (data2) {
							// console.log(data2.rt_bn_solution_tag_join,'getJSONkeyword');
							var keywordList = '';
							for (var k = 0; k < data2.rt_bn_solution_tag_join.length; k++) {
								keywordList += '<li><span class="mod-label-keyword mod-label-keyword01 opacity">' + data2.rt_bn_solution_tag_join[k].cf_pt_name + '</span></li>';
							}
							$('.js-solution-slider .mod-slider02_style-item').eq(i).find('.js-solution-slider-keyword').append(keywordList);
							if (i + 1 == $('.js-solution-slider .mod-slider02_style-item').length) {
								$('.js-solution-slider').slick({
									infinite: true,
									speed: 1000,
									autoplay: true,
									autoplaySpeed: 4000,
									slidesToShow: 1,
									arrows: true,
									prevArrow: '<button class="slick-arrow slick-prev opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_left2" /></svg></button>',
									nextArrow: '<button class="slick-arrow slick-next opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_right2" /></svg></button>'
								});
								$('.js-solution-slider').removeClass('di_none');
							}
						});
					});
				};

				for (var i = 0; i < $('.js-solution-slider .mod-slider02_style-item').length; i++) {
					_loop(i);
				}
			})();
		}
		if ($('.js-casestudy-slider').length) {
			(function () {
				var getJSONmaker = function getJSONmaker(_id) {
					return $.getJSON('/ajax/casestudy/pickup/maker/' + _id + '.json');
				};
				var getJSONkeyword = function getJSONkeyword(_id) {
					return $.getJSON('/ajax/casestudy/pickup/keyword/' + _id + '.json');
				};
				var getJSONindustry = function getJSONindustry(_id) {
					return $.getJSON('/ajax/casestudy/pickup/industry_type/' + _id + '.json');
				};

				var _loop2 = function _loop2(i) {
					getJSONmaker($('.js-casestudy-slider .mod-slider02_style-item').eq(i).find('.rt_cf_c_pickup_code').text()).then(function (data) {
						// console.log(data.rt_bn_maker_join,'getJSONmaker');
						var markerList = '';
						for (var l = 0; l < data.rt_bn_maker_join.length; l++) {
							markerList += '<li><span class="mod-label-keyword mod-label-keyword02 opacity">' + data.rt_bn_maker_join[l].cf_m_name + '</span></li>';
						}
						$('.js-casestudy-slider .mod-slider02_style-item').eq(i).find('.js-casestudy-slider-maker').append(markerList);
						getJSONkeyword($('.js-casestudy-slider .mod-slider02_style-item').eq(i).find('.rt_cf_c_pickup_code').text()).then(function (data2) {
							// console.log(data2.rt_bn_casestudy_tag_join,'getJSONkeyword');
							var keywordList = '';
							for (var k = 0; k < data2.rt_bn_casestudy_tag_join.length; k++) {
								keywordList += '<li><span class="mod-label-keyword mod-label-keyword01 opacity">' + data2.rt_bn_casestudy_tag_join[k].cf_pt_name + '</span></li>';
							}
							$('.js-casestudy-slider .mod-slider02_style-item').eq(i).find('.js-casestudy-slider-keyword').append(keywordList);
							getJSONindustry($('.js-casestudy-slider .mod-slider02_style-item').eq(i).find('.rt_cf_c_pickup_code').text()).then(function (data3) {
								console.log(data3, 'getJSONindustry');
								$('.js-casestudy-slider .mod-slider02_style-item').eq(i).find('.js-casestudy-slider-label').text(data3.rt_bn_casestudy_tag_join[0].cf_pt_name);
								if (i + 1 == $('.js-casestudy-slider .mod-slider02_style-item').length) {
									setTimeout(function () {
										$('.js-casestudy-slider').slick({
											infinite: true,
											speed: 1000,
											autoplay: true,
											autoplaySpeed: 4000,
											slidesToShow: 1,
											arrows: true,
											prevArrow: '<button class="slick-arrow slick-prev opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_left2" /></svg></button>',
											nextArrow: '<button class="slick-arrow slick-next opacity"><svg class="_icon"><use xlink:href="/common/svg/sprite.svg#arrow_right2" /></svg></button>'
										});
										$('.js-casestudy-slider').removeClass('di_none');
									}, 100);
								}
							});
						});
					});
				};

				for (var i = 0; i < $('.js-casestudy-slider .mod-slider02_style-item').length; i++) {
					_loop2(i);
				}
			})();
		}
	};
	var tabchange = function tabchange() {
		if ($('.js-tabchange').length) {
			$('.js-tabchange').on('click', function () {
				$(this).parents('.js-tabchangeWrap').find('.js-tabchange').removeClass(a);
				$(this).addClass(a);
				var index = $(this).parents('.js-tabchangeWrap').find('.js-tabchange').index(this);
				$(this).parents('.js-tabchangeWrap').find('.js-tabchange-content').removeClass(a).eq(index).addClass(a);
			});
			$('.js-tabchange').eq(0).addClass(a);
			$('.js-tabchange-content').eq(0).addClass(a);
		}
	};
	var currentLink = function currentLink() {
		if ($('.js-currentLink').length) {
			for (var i = 0; i < $('.js-currentLink a').length; i++) {
				if ($('.js-currentLink a').eq(i).attr('href') === location.pathname.replace('index.html', '')) {
					$('.js-currentLink a').eq(i).addClass(a);
				}
			}
		}
		if ($('.js-currentLink-directory').length) {
			for (var _i = 0; _i < $('.js-currentLink-directory a').length; _i++) {
				if ($('.js-currentLink-directory a').eq(_i).attr('href').split('/')[3].length) {
					if (location.pathname.replace('index.html', '').indexOf($('.js-currentLink-directory a').eq(_i).attr('href')) != -1) {
						$('.js-currentLink-directory a').eq(_i).addClass(a);
					}
				}
			}
		}
	};
	var moreButton = function moreButton() {
		if ($('.js-more-btn_2_10').length) {
			var moreBtn = $('.js-more-btn_2_10');
			var show = 10; //最初に表示する件数
			var num = 2; //clickごとに表示したい件数
			var contents = '.js-more-btn_2_10-list > li'; // 対象のlist
			$(contents + ':nth-child(n + ' + (show + 1) + ')').addClass('di_none');
			if ($('.js-more-btn_2_10-list > li').length < show + 1) {
				moreBtn.fadeOut();
			}
			moreBtn.on('click', function () {
				$(contents + '.di_none').slice(0, num).removeClass('di_none');
				if ($(contents + '.di_none').length == 0) {
					moreBtn.fadeOut();
				}
			});
		}
		if ($('.js-more-btn_8_20').length) {
			var _moreBtn = $('.js-more-btn_8_20');
			var _show = 20; //最初に表示する件数
			var _num = 8; //clickごとに表示したい件数
			var _contents = '.js-more-btn_8_20-list > li'; // 対象のlist
			$(_contents + ':nth-child(n + ' + (_show + 1) + ')').addClass('di_none');
			if ($('.js-more-btn_8_20-list > li').length < _show + 1) {
				_moreBtn.fadeOut();
			}
			_moreBtn.on('click', function () {
				$(_contents + '.di_none').slice(0, _num).removeClass('di_none');
				if ($(_contents + '.di_none').length == 0) {
					_moreBtn.fadeOut();
				}
			});
		}
		if ($('.js-more-btn_4_20').length) {
			var _moreBtn2 = $('.js-more-btn_4_20');
			var _show2 = 20; //最初に表示する件数
			var _num2 = 4; //clickごとに表示したい件数
			var _contents2 = '.js-more-btn_4_20-list > li'; // 対象のlist
			$(_contents2 + ':nth-child(n + ' + (_show2 + 1) + ')').addClass('di_none');
			if ($('.js-more-btn_4_20-list > li').length < _show2 + 1) {
				_moreBtn2.fadeOut();
			}
			_moreBtn2.on('click', function () {
				$(_contents2 + '.di_none').slice(0, _num2).removeClass('di_none');
				if ($(_contents2 + '.di_none').length == 0) {
					_moreBtn2.fadeOut();
				}
			});
		}
		if ($('.js-more-btn_5_10').length) {
			var _moreBtn3 = $('.js-more-btn_5_10');
			var _show3 = 10; //最初に表示する件数
			var _num3 = 5; //clickごとに表示したい件数
			var _contents3 = '.js-more-btn_5_10-list > li'; // 対象のlist
			$(_contents3 + ':nth-child(n + ' + (_show3 + 1) + ')').addClass('di_none');
			if ($('.js-more-btn_5_10-list > li').length < _show3 + 1) {
				_moreBtn3.fadeOut();
			}
			_moreBtn3.on('click', function () {
				$(_contents3 + '.di_none').slice(0, _num3).removeClass('di_none');
				if ($(_contents3 + '.di_none').length == 0) {
					_moreBtn3.fadeOut();
				}
			});
		}
		if ($('.js-more-btn_5_20').length) {
			var _moreBtn4 = $('.js-more-btn_5_20');
			var _show4 = 20; //最初に表示する件数
			var _num4 = 5; //clickごとに表示したい件数
			var _contents4 = '.js-more-btn_5_20-list > li'; // 対象のlist
			$(_contents4 + ':nth-child(n + ' + (_show4 + 1) + ')').addClass('di_none');
			if ($('.js-more-btn_5_20-list > li').length < _show4 + 1) {
				_moreBtn4.fadeOut();
			}
			_moreBtn4.on('click', function () {
				$(_contents4 + '.di_none').slice(0, _num4).removeClass('di_none');
				if ($(_contents4 + '.di_none').length == 0) {
					_moreBtn4.fadeOut();
				}
			});
		}
		if ($('.js-more-btn_news_5_20').length) {
			var _moreBtn5 = $('.js-more-btn_news_5_20');
			var _show5 = 20; //最初に表示する件数
			var _num5 = 5; //clickごとに表示したい件数
			var _contents5 = '.js-more-btn_news_5_20-list > li > div'; // 対象のlist
			$(_contents5 + ':nth-child(n + ' + (_show5 + 1) + ')').addClass('di_none');
			if ($('.js-more-btn_news_5_20-list > li > div').length < _show5 + 1) {
				_moreBtn5.fadeOut();
			}
			_moreBtn5.on('click', function () {
				$(_contents5 + '.di_none').slice(0, _num5).removeClass('di_none');
				if ($(_contents5 + '.di_none').length == 0) {
					_moreBtn5.fadeOut();
				}
			});
		}
	};
	var modal = function modal() {
		$('.mod-modal').on('click', function (e) {
			var HTML = '<div class="mod-modal-overlay">';
			HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
			$('body').append(HTML);
			var $overlay = $('.mod-modal-overlay');
			$overlay.addClass('is-active');
			setTimeout(function () {
				$overlay.addClass('is-visible');
				$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
					$overlay.removeClass('is-visible');
					setTimeout(function () {
						$overlay.removeClass('is-active');
						$overlay.remove();
					}, 400);
				});
			});
		});
		$('.mod-modal-video').on('click', function (e) {
			var HTML = '<div class="mod-modal-overlay">';
			HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + '<div class="_video"><div class="mod-youtube"><iframe width="560" height="315" src="https://www.youtube.com/embed/' + $(e.currentTarget).data('video') + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>' + '</div>';
			$('body').append(HTML);
			var $overlay = $('.mod-modal-overlay');
			$overlay.addClass('is-active');
			setTimeout(function () {
				$overlay.addClass('is-visible');
				$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
					$overlay.removeClass('is-visible');
					setTimeout(function () {
						$overlay.removeClass('is-active');
						$overlay.remove();
					}, 400);
				});
			});
		});
	};
	var formBtn = function formBtn() {
		// クリアボタン
		$('.js-form-clear').on('click', function () {
			$('input[type="text"]').val('');
			$('input[type="checkbox"]').prop('checked', false);
			$('input[type="radio"]').prop('checked', false);
			$('form').submit();
		});
	};
	var seminarDate = function seminarDate() {
		if ($('.js-seminar-date').length) {
			for (var i = 0; i < $('.js-seminar-date').length; i++) {
				console.log($('.js-seminar-date').eq(i).find('span').eq(0).text(), 'aa');
				console.log($('.js-seminar-date').eq(i).find('span').eq(1).text(), 'bb');
				if ($('.js-seminar-date').eq(i).find('span').eq(0).text() == $('.js-seminar-date').eq(i).find('span').eq(1).text()) {
					$('.js-seminar-date').eq(i).html('<span>' + $('.js-seminar-date').eq(i).find('span').eq(1).text() + '</span>');
				}
			}
		}
	};
	var imgHidden = function imgHidden() {
		if ($('.js-img-hidden').length) {
			for (var i = 0; i < $('.js-img-hidden').length; i++) {
				if (!$('.js-img-hidden').eq(i).find('img').length) {
					$('.js-img-hidden').eq(i).addClass('di_none');
					if ($('.js-img-hidden').eq(i).parents('.js-img-hiddenWrap').length) {
						$('.js-img-hidden').eq(i).parents('.js-img-hiddenWrap').find('.pure-u-1-2').css('width', '100%');
					}
				}
			}
		}
	};
	removeElmIfEmpty();
	removeBlockIfListEmpty();
	slider();
	tabchange();
	currentLink();
	moreButton();
	modal();
	formBtn();
	seminarDate();
	imgHidden();
})(window.jQuery, window.NETWORLD);