'use strict';

window.console || (window.console = {
	log: function log() {}
});
var NETWORLD = window.NETWORLD || null;
var cssua = window.cssua || null;

(function ($) {
	var htmlHasClass = function htmlHasClass(_class) {
		return $('html').hasClass(_class);
	};
	var siteName = 'networld';
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '300';

	NETWORLD = {
		va: {
			pathname: location.pathname,
			isEnglish: /^\/english\//.test(location.pathname) ? true : false,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function (e) {
				$(e.currentTarget).toggleClass(a);
				$(e.currentTarget).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
			if ($('.js-toggleBlock').length) {
				for (var i = 0; i < $('.js-toggleBlock').length; i++) {
					// console.log($('.js-toggleBlock').eq(i).find('input[type="checkbox"]:checked').length);
					if ($('.js-toggleBlock').eq(i).find('input[type="checkbox"]:checked').length) {
						$('.js-toggleBlock').eq(i).css('display', 'block');
						$('.js-toggleBlock').eq(i).prev('.js-toggle').addClass(a);
					}
				}
			}
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (NETWORLD.va.window.width < NETWORLD.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 100;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash) {
				$('html, body').css('opacity', '0');
				if (document.querySelectorAll('.bclo-imgWrap img').length) {
					var getImg = function getImg(_src) {
						return new Promise(function (resolve, reject) {
							var image = new Image();
							image.src = _src;
							image.onload = function () {
								resolve(image);
							};
							image.onerror = function (error) {
								reject(error);
							};
						});
					};
					var imgs = document.querySelectorAll('.bclo-imgWrap img');

					var _loop = function _loop(img) {
						var src = img.getAttribute('src');
						getImg(src).then(function (res) {
							if (!img.hasAttribute('width')) {
								img.setAttribute('width', res.width);
							}
							if (!img.hasAttribute('height')) {
								img.setAttribute('height', res.height);
							}
						}).catch(function (error) {
							console.log(error);
						});
					};

					var _iteratorNormalCompletion = true;
					var _didIteratorError = false;
					var _iteratorError = undefined;

					try {
						for (var _iterator = imgs[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
							var img = _step.value;

							_loop(img);
						}
					} catch (err) {
						_didIteratorError = true;
						_iteratorError = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion && _iterator.return) {
								_iterator.return();
							}
						} finally {
							if (_didIteratorError) {
								throw _iteratorError;
							}
						}
					}
				}
				$(window).on('load', function () {
					if (document.querySelectorAll('.bclo-imgWrap img').length) {
						setTimeout(function () {
							$('html, body').css('opacity', '1');
							var _hash = '#' + hash.split('#')[1];
							var pos = $(_hash).offset().top;

							if (NETWORLD.va.window.width < NETWORLD.va.device.sp) {
								pos -= 60;
							} else {
								pos -= 100;
							}
							$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
						}, 300);
					} else {
						setTimeout(function () {
							$('html, body').css('opacity', '1');
							var _hash = '#' + hash.split('#')[1];
							var pos = $(_hash).offset().top;

							if (NETWORLD.va.window.width < NETWORLD.va.device.sp) {
								pos -= 60;
							} else {
								pos -= 100;
							}
							$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
						}, 300);
					}
				});
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			if ($pagetop.length) {
				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var $footer = $('.footer');
					var footerPos = $footer.offset().top + $footer.height();
					var thisScroll = $this.scrollTop();
					var pagetopPos = $this.height() + $footer.height() + thisScroll;
					var doch = $(document).innerHeight();
					var winh = $(window).innerHeight();
					var bottom = doch - winh;

					if (thisScroll > '500') {
						$pagetop.addClass(v);
					} else {
						$pagetop.removeClass(v);
					}
					if (NETWORLD.va.window.width) {
						if (bottom <= $(window).scrollTop()) {
							$pagetop.css('bottom', '278px');
						} else {
							$pagetop.css('bottom', '10px');
						}
					}
					if (NETWORLD.va.window.width < NETWORLD.va.device.sp) {
						if (bottom <= $(window).scrollTop()) {
							$pagetop.css('bottom', '130px');
						} else {
							$pagetop.css('bottom', '10px');
						}
					}
				});
			}
		},
		headerMenu: function headerMenu() {
			var d = 'is-down';
			var $hamburger = $('.header-hamburger');
			var $nav = $('.header-nav');
			var $search = $('.header-search');
			var $searchBtn = $('.header-btns-search');

			if (NETWORLD.va.window.width <= 1024) {
				$('.header-hamburger').on('click', function (e) {
					if ($searchBtn.hasClass(a)) {
						$searchBtn.removeClass(a);
						$search.stop().slideUp(slideSpeed);
					}
					$(e.currentTarget).toggleClass(a);
					$(".overlay").fadeToggle();
					$nav.toggleClass('in');
				});

				$('.js-spheader-toggle').on('click', function (e) {
					e.preventDefault();
					$(e.currentTarget).toggleClass(d);
					$(e.currentTarget).next('.header-nav-inner').stop().slideToggle(slideSpeed);
				});
			}
			$(document).on('click', '.header-search-close', function () {
				$searchBtn.removeClass(a);
				$search.removeClass(v);
				setTimeout(function () {
					$search.removeClass(a);
				}, 200);
			});
		},
		headerCurrent: function headerCurrent() {
			$('.header-nav-head').each(function (idx, ele) {
				if (NETWORLD.va.pathname.indexOf($(ele).attr('href')) !== -1) {
					$(ele).addClass(a);
				}
			});
		},
		headerPcMenuToggle: function headerPcMenuToggle() {
			$('.js-pcmenu-toggle').on('mouseover', function (e) {
				$('.js-pcmenu').addClass(a);
				!$(e.target).addClass(a);
			});
			$(document).on('mouseover', function (e) {
				if (!$(e.target).closest('.js-pcmenu-toggle, .js-pcmenu').length) {
					$('.js-pcmenu').removeClass(a);
					$('.js-pcmenu-toggle').removeClass(a);
				}
			});
		},
		bgImage: function bgImage() {
			if ($('.js-bg-image').length) {
				for (var i = 0; i < $('.js-bg-image').length; i++) {
					if ($('.js-bg-image').eq(i).data('img')) {
						$('.js-bg-image').eq(i).css('background-image', 'url(' + $('.js-bg-image').eq(i).data('img') + ')');
					}
				}
			}
		},

		// ページを訪問時にcookie保存
		jscookie: function jscookie() {
			if ($('.js_maker_cookie').length) {
				var makerName = $('.js_maker_cookie').text();
				var now = new Date();
				now.setDate(now.getDate() + 3);
				document.cookie = 'networld_js_maker_cookie_' + makerName + '=' + makerName + ';expires=' + now.toUTCString() + ';path=/;';
			}
		},
		fileSizeEx: function fileSizeEx() {
			if ($('.js-fileSize').length) {
				$('.js-fileSize').each(function () {
					$(this).text('（' + bytesToSize($(this).text()) + '）');
				});
			}
			function bytesToSize(bytes) {
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes === 0) {
					return 'n/a';
				}
				var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
				if (i === 0) {
					return bytes + ' ' + sizes[i];
				}
				return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
			}
		},
		jslistonehidden: function jslistonehidden() {
			if ($('.js-list-one-hidden').length) {
				if ($('.js-list-one-hidden').find('li').length == 1) {
					$('.js-list-one-hidden').addClass('di_none');
				}
			}
		},
		share: function share() {
			var pageUrl = document.URL;
			var shareUrl = "";
			var sharePagetitle = document.title;
			// facebook
			if ($('.js-sns-facebook').length) {
				shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + pageUrl;
				$('.js-sns-facebook').attr('href', shareUrl);
			}
			// twitter
			if ($('.js-sns-twitter').length) {
				shareUrl = 'https://twitter.com/share?text=' + encodeURIComponent(sharePagetitle) + '&url=' + pageUrl;
				$('.js-sns-twitter').attr('href', shareUrl);
			}
			// linkedin
			if ($('.js-sns-linkedin').length) {
				shareUrl = 'https://www.linkedin.com/shareArticle?mini=true&url=' + pageUrl + '&title=' + encodeURIComponent(sharePagetitle);
				$('.js-sns-linkedin').attr('href', shareUrl);
			}
		},
		lazyload: function lazyload() {
			if ($(".js-lazy-img").length) {
				$(".js-lazy-img").lazyload();
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.headerMenu();
			_this.headerCurrent();
			_this.headerPcMenuToggle();
			_this.bgImage();
			_this.jscookie();
			_this.fileSizeEx();
			_this.jslistonehidden();
			_this.share();
			_this.lazyload();
		}
	};

	$(function () {
		return NETWORLD.localDecision() ? NETWORLD.localLoading() : NETWORLD.loadDelayScript();
	});
})(window.jQuery);